import moment from "moment";
import { CaretLeft, CaretRight, EnvelopeOpen, List, X } from "phosphor-react";
import { Card } from "primereact/card";
import { Menu } from "primereact/menu";
import { ProgressBar } from "primereact/progressbar";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { BroadcastInboxProps } from "../../types/components/queue/broadcastInboxProps";
import { ClinicianInbox } from "../../types/consult-api";
import { getBroadcastTimeAgo, getMessageTimeAgo } from "../../utils/getMessageTimeAgo";

const BroadcastInbox = (props: BroadcastInboxProps) => {
    const options = ["Unread", "Read"];
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState<ClinicianInbox[]>([]);
    const [selectedMessage, setSelectedMessage] = useState<ClinicianInbox | null>(null);
    const [showInbox, setShowInbox] = useState(true);
    const [value, setValue] = useState(options[0]);

    const tabRef = useRef(value);
    const toast = useRef<Toast | null>(null);

    // Maintain the tab state when viewing and exiting messages
    useEffect(() => {
        tabRef.current = value;
    }, [value]);

    useEffect(() => {
        const getMessages = async () => {
            setIsLoading(true);
            const status = value === "Unread" ? "unread" : "read";
            const fetchedMessages = await props.methods.getMessages(status, toast);
            setMessages(fetchedMessages);
            setIsLoading(false);
        };

        getMessages();
        const intervalId = setInterval(getMessages, 60000);
        return () => clearInterval(intervalId);
    }, [value]);

    const handleMarkAsRead = async (message: ClinicianInbox) => {
        message.readAt = moment.now().toString();
        message.inUnreadInbox = false;
        await props.methods.markAsRead([message.messageId], props.clinician, toast);
        setMessages((prevMessages) => prevMessages.filter((m) => m.messageId !== message.messageId));
    };

    const header = (
        <p className={"text-base font-semibold"} style={{ margin: 0 }}>
            Inbox
        </p>
    );

    const MessagePreview = ({ message }: { message: ClinicianInbox }) => {
        if (!message) {
            return <></>;
        }

        return (
            <div
                className={"message-preview"}
                onClick={() => {
                    handleMarkAsRead(message);
                    setSelectedMessage(message);
                }}
            >
                <div className={"top-row"}>
                    <div className={"left-side"}>
                        <div className={"new-indicator"} style={{ visibility: message.inUnreadInbox === true ? "visible" : "hidden" }} />
                        <div className={"sender text-sm font-semibold"}>{message.createdBy}</div>
                    </div>
                    <div className={"right-side"}>
                        <div className={"timestamp"}>{getBroadcastTimeAgo(message.createdAt)}</div>
                        <CaretRight size={16} weight={"bold"} />
                    </div>
                </div>
                <div className={"subject-line text-sm font-normal"}>{message.subject}</div>
                <div className={"body-text-preview"}>
                    <ReactMarkdown>{message.message}</ReactMarkdown>
                </div>
            </div>
        );
    };

    const FullMessageView = ({ message }: { message: ClinicianInbox }) => {
        if (!message) {
            return <></>;
        }

        const [showDropdown, setShowDropdown] = useState(false);
        const menu = useRef<Menu>(null);
        const menuItems = [
            {
                label: "Mark as unread",
                command: async () => {
                    message.inUnreadInbox = true;
                    await props.methods.markAsUnread([message.messageId], props.clinician, toast);
                    setMessages(messages.filter((m) => m.messageId !== message.messageId));
                    setValue("Unread");
                },
            },
        ];

        return (
            <>
                <Toast ref={toast} onHide={() => {}} />
                <div className={"full-message-view"}>
                    <div className={"navigation-bar"}>
                        <div className={"left-side"}>
                            <div
                                className={"back-button"}
                                onClick={() => {
                                    setSelectedMessage(null);
                                    setValue(tabRef.current);
                                }}
                            >
                                <CaretLeft size={16} weight={"bold"} />
                                <div className={"text-sm font-normal"}>Back</div>
                            </div>
                        </div>
                        <div className={"right-side"}>
                            <List size={16} weight={"bold"} className={"hamburger-menu-button"} onClick={() => setShowDropdown(!showDropdown)} />
                            {showDropdown && (
                                <Menu
                                    model={menuItems}
                                    ref={menu}
                                    className={"inbox-dropdown"}
                                    style={{ position: "absolute", width: "unset", right: "16px" }}
                                />
                            )}
                        </div>
                    </div>
                    <div className={"message-content"}>
                        <div className={"top-row"}>
                            <div className={"left-side"}>
                                <div className={"new-indicator"} style={{ visibility: message.inUnreadInbox === true ? "visible" : "hidden" }} />
                                <div className={"sender text-sm font-semibold"}>{message.createdBy}</div>
                            </div>
                            <div className={"right-side"}>
                                <div className={"timestamp"}>{getMessageTimeAgo(message.createdAt)}</div>
                            </div>
                        </div>
                        <div className={"subject text-sm font-semibold"}>{message.subject}</div>
                        <div className={"body"}>
                            <ReactMarkdown>{message.message}</ReactMarkdown>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const ToggleButton = () => {
        const icon = showInbox ? <X size={16} weight={"bold"} /> : <EnvelopeOpen size={16} weight={"bold"} />;
        const label = showInbox ? "Hide Inbox" : "Show Inbox";

        return (
            <div className={"clinician-inbox-toggle-button"} onClick={() => setShowInbox(!showInbox)}>
                {icon}
                <div className={"text-sm font-normal button-label"}>{label}</div>
            </div>
        );
    };

    const EmptyState = () => (
        <div className={"inbox-empty-state"}>
            <img src={"empty-inbox.svg"} alt={"Empty Inbox"} />
            <div className={"text"}>
                <p className={"text-sm font-semibold"}>No Messages</p>
                <p className={"text-sm font-normal"}>You're all caught up!</p>
            </div>
        </div>
    );

    const LoadingState = () => (
        <div className={"inbox-loading-state"}>
            <ProgressBar mode="indeterminate" style={{ height: "2px", width: "100%", alignSelf: "center" }} />
            <div className={"text"}>
                <p className={"text-sm font-normal"}>Loading messages...</p>
            </div>
        </div>
    );

    return (
        <div className={"clinician-inbox-with-button"}>
            {showInbox && (
                <Card header={header} className={"clinician-inbox"}>
                    {!selectedMessage ? (
                        <>
                            <div className={"inbox-navigation-tabs"}>
                                <SelectButton value={value} onChange={(e) => setValue(e.value)} options={options} allowEmpty={false} />
                            </div>
                            <div className={"inbox-container"}>
                                {isLoading ? (
                                    <LoadingState />
                                ) : messages.length > 0 ? (
                                    messages.map((message) => <MessagePreview key={message.messageId} message={message} />)
                                ) : (
                                    <EmptyState />
                                )}
                            </div>
                        </>
                    ) : (
                        <FullMessageView message={selectedMessage} />
                    )}
                </Card>
            )}
            <ToggleButton />
        </div>
    );
};

export default BroadcastInbox;
