import { ConsultQueue, ConsultQueueVisitStatusEnum, ConsultTags, ConsultTagsTagEnum } from "../../consult-api";
import { Episode } from "../../emr-api";

export interface ConsultCardProps {
    isDueNow: boolean;
    isInitial: boolean;
    isOnHold: boolean;
    isP1: boolean;
    isP2: boolean;
    consult: ConsultQueue;
    disabled?: boolean;
    episode?: Episode;
    index?: number;
    methods: {
        setUnavailableCss: () => string;
    };
}

export function create(consult: ConsultQueue, tabIndex: Number, index?: Number, episode?: Episode): ConsultCardProps {
    return {
        isDueNow: index == 0 && tabIndex == 0,
        isInitial: !!consult.tags.find((consultTags: ConsultTags) => consultTags.tag === ConsultTagsTagEnum.Initial),
        isOnHold: consult.isPauseAllowed && consult.visitStatus === ConsultQueueVisitStatusEnum.ReadyToResume && consult.reason === "On hold",
        isP1: !!consult.tags.find((consultTags: ConsultTags) => consultTags.tag === ConsultTagsTagEnum.P1),
        isP2: !!consult.tags.find((consultTags: ConsultTags) => consultTags.tag === ConsultTagsTagEnum.P2),
        consult,
        episode,
        methods: {
            setUnavailableCss: function () {
                switch (consult.visitStatus) {
                    case ConsultQueueVisitStatusEnum.ReadyToResume:
                    case ConsultQueueVisitStatusEnum.Assigned:
                    case ConsultQueueVisitStatusEnum.Unassigned:
                    case ConsultQueueVisitStatusEnum.InProgress:
                    case ConsultQueueVisitStatusEnum.ExternalInProgress:
                    case ConsultQueueVisitStatusEnum.InCall:
                    case ConsultQueueVisitStatusEnum.Waiting:
                    case ConsultQueueVisitStatusEnum.Scheduled:
                    case ConsultQueueVisitStatusEnum.Received:
                        return "";
                    default:
                        return "opacity-60";
                }
            },
        },
    };
}
