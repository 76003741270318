import * as Sentry from "@sentry/browser";
import React from "react";
import { ClinicianApi } from "../../clinician-api";
import { ConsultReasonForVisitEnum } from "../../consult-api";

const reasonMapping: { [key in ConsultReasonForVisitEnum]: { label: string; value: string } } = {
    [ConsultReasonForVisitEnum.Initial]: { label: "Initial", value: "initial" },
    [ConsultReasonForVisitEnum.FollowUp]: { label: "Follow Up", value: "follow_up" },
    [ConsultReasonForVisitEnum.SideEffect]: { label: "Side Effect", value: "side_effect" },
    [ConsultReasonForVisitEnum.PharmacyChange]: { label: "Pharmacy Change", value: "pharmacy_change" },
    [ConsultReasonForVisitEnum.MedicationChange]: { label: "Medication Change", value: "medication_change" },
    [ConsultReasonForVisitEnum.AdditionalDocumentation]: { label: "Additional Documentation", value: "additional_documentation" },
    [ConsultReasonForVisitEnum.LabResult]: { label: "Lab Result", value: "lab_result" },
    [ConsultReasonForVisitEnum.AdhocFollowUp]: { label: "Follow Up", value: "follow_up" },
    [ConsultReasonForVisitEnum.AdhocSideEffect]: { label: "Side Effect", value: "side_effect" },
    [ConsultReasonForVisitEnum.AdhocPharmacyChange]: { label: "Pharmacy Change", value: "pharmacy_change" },
    [ConsultReasonForVisitEnum.AdhocPharmacyCall]: { label: "Pharmacy Call", value: "adhoc_pharmacy_call" },
    [ConsultReasonForVisitEnum.AdhocMedicationChange]: { label: "Medication Change", value: "medication_change" },
    [ConsultReasonForVisitEnum.AdhocAdditionalDocumentation]: { label: "Additional Documentation", value: "additional_documentation" },
    [ConsultReasonForVisitEnum.Empty]: { label: "", value: "" },
    [ConsultReasonForVisitEnum.Null]: { label: "", value: "" },
};

export interface ConsultCardFilterProps {
    methods: {
        getReasonForVisitOptions: () => { label: string; value: string }[];
        logAsyncOnlyFilterActivity: (isAsyncOnly: boolean, reason?: string, selectedDuration?: string, toast?: React.MutableRefObject<any>) => Promise<boolean>;
        onIsAsyncConsultsOnlyChange: (value?: boolean) => void;
        onConsultChange: (value?: string[]) => void;
        onPartnerChange: (value?: string[]) => void;
        onProgramChange: (value?: string[]) => void;
        onConsultDifficultyChange: (minValue?: number, maxValue?: number) => void;
        onReasonForVisitChange: (value?: ConsultReasonForVisitEnum[]) => void;
    };
}

export function create(
    onIsAsyncConsultsOnlyChange: () => void,
    onConsultChange: () => void,
    onPartnerChange: () => void,
    onProgramChange: () => void,
    onConsultDifficultyChange: (minValue?: number, maxValue?: number) => void,
    onReasonForVisitChange: () => void
): ConsultCardFilterProps {
    const uniqueOptions = new Set<string>();
    return {
        methods: {
            getReasonForVisitOptions: function () {
                return Object.keys(ConsultReasonForVisitEnum)
                    .map((key) => {
                        const value = ConsultReasonForVisitEnum[key as keyof typeof ConsultReasonForVisitEnum];
                        return reasonMapping[value];
                    })
                    .filter((option) => {
                        if (option.value !== "" && option.value !== "null" && !uniqueOptions.has(option.value)) {
                            uniqueOptions.add(option.value);
                            return true;
                        }
                        return false;
                    });
            },

            logAsyncOnlyFilterActivity: async function (
                isAsyncConsultsOnly: boolean,
                reason?: string,
                selectedDuration?: string,
                toast?: React.MutableRefObject<any>
            ) {
                const client = new ClinicianApi();
                try {
                    await client.clinician.clinicianLogActivityCreate({ isAsyncConsultsOnly, reason, selectedDuration });
                    return true;
                } catch (err) {
                    Sentry.captureException(err);
                    toast?.current?.show({
                        severity: "error",
                        sticky: true,
                        summary: "Error submitting your reason",
                        detail: err.message ? `${err.message}` : "Please try again later.",
                        className: "error-toast",
                    });
                }
                return false;
            },

            onIsAsyncConsultsOnlyChange,
            onConsultChange,
            onPartnerChange,
            onProgramChange,
            onConsultDifficultyChange,
            onReasonForVisitChange,
        },
    };
}
