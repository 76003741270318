import * as Sentry from "@sentry/browser";
import React from "react";
import { ClinicianApi } from "../../clinician-api";
import { Clinician, ClinicianInbox } from "../../consult-api";

export interface BroadcastInboxProps {
    clinician: Clinician;
    methods: {
        getMessages: (status: string, toast: React.MutableRefObject<any>) => Promise<ClinicianInbox[]>;
        markAsRead: (messageIds: number[], clinician: Clinician, toast: React.MutableRefObject<any>) => void;
        markAsUnread: (messageIds: number[], clinician: Clinician, toast: React.MutableRefObject<any>) => void;
    };
}

export function create(clinician: Clinician): BroadcastInboxProps {
    return {
        clinician,

        methods: {
            getMessages: async function (status: string, toast: React.MutableRefObject<any>) {
                const client = new ClinicianApi();
                try {
                    const response = await client.clinician.clinicianInboxList(status);
                    return response.data;
                } catch (err) {
                    Sentry.captureException(err);
                    toast.current?.show({
                        severity: "error",
                        sticky: true,
                        summary: "Failed to fetch broadcast messages",
                        detail: err.message,
                        className: "error-toast",
                    });
                }
                return [];
            },

            markAsRead: async function (messageIds: number[], clinician: Clinician, toast: React.MutableRefObject<any>) {
                const client = new ClinicianApi();
                try {
                    await client.clinician.clinicianInboxMarkMessageAsUpdate(messageIds.join(","), clinician, "read");
                } catch (err) {
                    Sentry.captureException(err);
                    toast.current?.show({
                        severity: "error",
                        sticky: true,
                        summary: "Failed to mark message as read",
                        detail: err.message,
                        className: "error-toast",
                    });
                }
            },

            markAsUnread: async function (messageIds: number[], clinician: Clinician, toast: React.MutableRefObject<any>) {
                const client = new ClinicianApi();
                try {
                    await client.clinician.clinicianInboxMarkMessageAsUpdate(messageIds.join(","), clinician, "unread");
                } catch (err) {
                    Sentry.captureException(err);
                    toast.current?.show({
                        severity: "error",
                        sticky: true,
                        summary: "Failed to mark message as unread",
                        detail: err.message,
                        className: "error-toast",
                    });
                }
            },
        },
    };
}
